html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Lato';
}

@media screen and (max-width: 767px) {
  html { font-size: 95%; }
}

@media (-webkit-device-pixel-ratio: 1.5) { 
  html { font-size: 70%; }
 }
